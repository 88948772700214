@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,500;1,500&display=swap");

body {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 110px;
  right: 40px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #00000000;
  z-index: 100;
}

.call_float_round {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 40px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #00000000;
  z-index: 100;
}

.call_float {
  position: fixed;
  right: 30px;
  bottom: 50px;
  background-color: #dc3545; /* Adjust color */
  color: white;
  padding: 14px 20px;
  border-radius: 12px 12px 12px 12px; /* Round only the top corners */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out;
  z-index: 1000;
  text-decoration: none; /* Removes underline */
}

/* Full-width button for mobile */
@media (max-width: 768px) {
  .call_float {
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 12px 12px 0 0; /* Keep rounded top corners */
    text-align: center;
    padding: 16px 0;
  }
}

.call_float:hover {
  background-color: red; /* Darker shade */
}

.whatsapp-icon {
  margin-top: 16px;
}

.call-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 70px;
    right: 10px;
    font-size: 22px;
  }

  .call-icon {
    margin-top: 10px;
  }

  .call_float_round {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

.navbar-home {
  position: fixed;
  width: 100%;
}

.navbar-other {
  background-color: #000000;
  background: black;
  width: 100%;
}

.heading {
  color: #ffffff;
}

.hero-section {
  position: relative;
  width: 100%;
  min-height: 90vh;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.hero-content-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 40px 0;
  background: rgba(0, 0, 0, 0);
}

.hero-section .container {
  z-index: 1;
}

.nav-pills .nav-link {
  background-color: rgb(48, 48, 48);
  color: #ffffff;
  padding: 5px;
}

.nav-item {
  margin: 7px;
}

.nav-pills .nav-link.active {
  background-color: #dc3545;
  color: white;
}

.container {
  width: 100;
}

.usp-card {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  background-color: rgb(48, 48, 48);
  border: 1px solid #000000;
}

.usp-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.usp-icon {
  color: #ffffff;
  transition: color 0.3s ease;
}

.usp-card:hover .usp-icon {
  color: #dc3545;
}

.usp-card .card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #c2c2c2;
  letter-spacing: 0.7px;
}

.usp-card .card-text {
  color: #ffffff;
}

.accordion-button {
  background-color: black !important;
  border-color: #000000 !important;
  color: rgb(121, 121, 121) !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #dc3545 !important;
  background-color: #000000 !important;
}

.accordion-body {
  background-color: rgb(15, 15, 15) !important;
  color: white !important;
}

.experience-zone {
  background-size: cover;
  background-position: center;
  height: 300px; /* Adjust this value to control the height */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Optional: for contrast */
}

.experience-zone h2 {
  font-size: 2rem; /* Optional: styling for your text */
}

.experience-zone p {
  font-size: 1rem;
}

.dark-select {
  background-color: #333333;
  color: #fff;
}

.dark-select:focus {
  background-color: #333333;
  border-color: #ffffff;
  color: #fff;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #dc3545;
  border-radius: 50%;
  cursor: pointer;
}

.custom-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #dc3545;
  border-radius: 50%;
  cursor: pointer;
}

.custom-slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #dc3545;
  border-radius: 50%;
  cursor: pointer;
}

.custom-slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 8px;
  background: white;
  border-radius: 4px;
}

.custom-slider::-moz-range-track {
  height: 8px;
  background: white;
  border-radius: 4px;
}

.custom-slider::-ms-track {
  height: 8px;
  background: white;
  border-radius: 4px;
  border-color: transparent;
  color: transparent;
}

html,
body {
  margin: 0;
  padding: 0;
  background: #000;
}

.dropdown-item {
  background-color: #333333;
  color: #fff;
}

.dropdown-menu.show {
  background-color: #333333;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  overflow-y: scroll;
  padding: 10px;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #ccc transparent; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  height: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.review-card {
  flex: 0 0 auto;
  width: 300px;
  margin-bottom: 20px;
}

.free-shipping-banner {
  background: #151515;
  color: white; /* Text color */
  padding: 40px 20px;
}

.text-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.text-section p {
  font-size: 1.25rem;
}

.image-section img {
  max-height: 300px;
  object-fit: contain;
}
